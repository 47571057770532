import React from 'react';
import { ListChildComponentProps } from 'react-window';
import Currency from '../../../../../components/format/currency/Currency';
import FormattedDate from '../../../../../components/format/date/formatted-date';
import Loading from '../../../../../components/loading/loading';
import { CreditToList } from '../../../../../model/credit';
import {
    INVOICE_SELECTION_ITEM_HEIGHT_PADDING,
    INVOICE_SELECTION_ITEM_LEFT_PADDING,
    INVOICE_SELECTION_ITEM_WIDTH,
    INVOICE_SELECTION_TOP_PADDING
} from '../constants';

const CheckIcon = ({ isSelected }) => {
    if (isSelected) {
        return <div className="icon-check2" />;
    }
    return <div className="icon-check" />;
};

const ADD_TO_STYLE = (property: string | number | undefined, value: number) => {
    if (property === null || property === undefined || isNaN(Number(property))) return;
    return Number(property) + value;
};

export interface InvoiceListItemProps extends ListChildComponentProps {
    isSelected: (id: string) => boolean;
    onSelect: (credit: CreditToList) => void;
}

export const InvoiceListItem = (props: InvoiceListItemProps) => {
    const { index, data, style, isSelected, onSelect } = props;
    const item: CreditToList | undefined = data && data[index];

    const checked: boolean = !!item && isSelected(item.id);

    const handleSelect = () => {
        if (!item) return;

        onSelect(item);
    };

    if (!item) {
        return (
            <div style={style} className="anticipation-invoice-selection__invoice-list--loading">
                <Loading />
            </div>
        );
    }

    const NEW_STYLES: React.CSSProperties = {
        ...style,
        top: ADD_TO_STYLE(style.top, INVOICE_SELECTION_TOP_PADDING),
        left: ADD_TO_STYLE(style.left, INVOICE_SELECTION_ITEM_LEFT_PADDING),
        height: ADD_TO_STYLE(style.height, -INVOICE_SELECTION_ITEM_HEIGHT_PADDING),
        width: INVOICE_SELECTION_ITEM_WIDTH
    };

    return (
        <div
            // required https://www.npmjs.com/package/react-window#why-is-my-list-blank-when-i-scroll
            style={NEW_STYLES}
            className="invoice-list-item__items"
            onClick={handleSelect}
        >
            <div className="invoice-list-item__items--cell">{item.nfNumber}</div>
            <div className="invoice-list-item__items--cell">{item.nfSerial}</div>
            <div className="invoice-list-item__items--cell">{item.installmentNumber ?? ''}</div>
            <div className="invoice-list-item__items--cell">
                <FormattedDate date={item.expireDate} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.originalValue} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.discountedValue} />
            </div>
            <div className="invoice-list-item__items--cell">
                <Currency value={item.anticipationValue} />
            </div>

            <div className="invoice-list-item__items--cell">
                <CheckIcon isSelected={checked} />
            </div>
        </div>
    );
};

export default InvoiceListItem;
