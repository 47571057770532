import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GIRO_API_ACCOUNT_MANAGE, GIRO_API_ADDRESS_MANAGE, GIRO_API_FILES_MANAGE, GIRO_API_SIGNER, GIRO_API_TERMS_WEBVIEW, GIRO_API_URL, GIRO_TECH_URL, INVITE_REDICT_URL } from '../../config/constants';
import AcceptInvite from '../../features/accept-invite/accept-invite';
import AcceptTermsOfUse from '../../features/accept-terms-of-use/accept-terms-of-use';
import AnticipationInvoices from '../../features/anticipation/invoices/anticipation-invoices';
import AnticipationSimulation from '../../features/anticipation/simulation/anticipation-simulation';
import Dashboard from '../../features/dashboard/dashboard';
import { FooterType } from '../../features/footer/footer';
import ForgotPassword from '../../features/forgot-password/forgot-password';
import { HeaderType } from '../../features/header/header';
import { Login } from '../../features/login/login';
import PasswordReset from '../../features/password-reset/password-reset';
import Profile from '../../features/profile/profile';
import ProgramSelection from '../../features/program-selection/program-selection';
import PageNotFound from '../error/page-not-found';
import WebChat from '../web-chat/web-chat';
import ExternalRoute from './external-route';
import PrivateRoute from './private-route';
import PublicRoute from './public-route';

export interface AppRoutesProps {
    basename: string;
}

const AppRoutes = (props: AppRoutesProps) => {
    return (
        <BrowserRouter basename={props.basename}>
            <Switch>
                <PublicRoute exact path="/" component={Login} headerType={HeaderType.NONE} footerType={FooterType.NONE} />
                <PublicRoute exact path="/accept-invite/:token" component={AcceptInvite} />
                <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                <PublicRoute exact path="/password-reset/:key" component={PasswordReset} />

                <PrivateRoute exact path="/profile" component={Profile} />

                <PrivateRoute exact path="/accept-terms-of-use" component={AcceptTermsOfUse} headerType={HeaderType.SIMPLE} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/program-selection" component={ProgramSelection} />

                <PrivateRoute exact path="/anticipation/invoices" component={AnticipationInvoices} />
                <PrivateRoute exact path="/anticipation/simulation" component={AnticipationSimulation} />

                <ExternalRoute exact path="/giro-api" externalUrl={GIRO_API_URL} />
                <ExternalRoute exact path="/invite-redirect" externalUrl={INVITE_REDICT_URL}/>
                <ExternalRoute exact path="/giro.tech" externalUrl={GIRO_TECH_URL} />
                <ExternalRoute exact path="/full-terms" externalUrl={GIRO_API_TERMS_WEBVIEW} />

                <ExternalRoute exact path="/company-address-manage" externalUrl={GIRO_API_ADDRESS_MANAGE} />
                <ExternalRoute exact path="/company-bank-account-manage" externalUrl={GIRO_API_ACCOUNT_MANAGE} />
                <ExternalRoute exact path="/signer" externalUrl={GIRO_API_SIGNER} />
                <ExternalRoute exact path="/company-files-manage" externalUrl={GIRO_API_FILES_MANAGE} />

                <PublicRoute component={PageNotFound} />
            </Switch>
            <WebChat />
        </BrowserRouter>
    );
};

export default AppRoutes;
