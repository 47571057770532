import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { providerCreditsReducer } from './provider/reducer';
import providerCreditsSaga from './provider/sagas';
import { ProviderCreditsState } from './provider/types';

export interface CreditState {
    readonly providerCredits: ProviderCreditsState;
}

export const CreditReducer: ReducersMapObject<CreditState, AnyAction> = {
    providerCredits: providerCreditsReducer
};

export function* creditSagas() {
    yield all([fork(providerCreditsSaga)]);
}
