import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectableColumn from '../../../../../components/listing/selectable-column/selectable-column';
import SortableColumn, { Sortable } from '../../../../../components/listing/sortable-column/sortable-column';
import { useInvoiceSelectionContext } from '../invoice-selection/invoice-selection-context';

const mapSortProperties = (sort?: string) => {
    const split = sort?.split(',');
    const sortedProperty = split ? split[0] : undefined;
    const sortOrder: 'asc' | 'desc' | undefined = split && (split[1] === 'asc' || split[1] === 'desc') ? split[1] : undefined;
    return { sortedProperty, sortOrder };
};

export const InvoiceHeader = () => {
    const { t } = useTranslation();
    const { isAllSelected, isLoading, sort, disableSortAndSelect, handleSelectAll, requestFirstPage } = useInvoiceSelectionContext();

    const { sortedProperty, sortOrder } = mapSortProperties(sort);

    const handleSort = (_property: string, order?: 'asc' | 'desc') => {
        if (!_property) return;
        if (_property !== sortedProperty) {
            requestFirstPage(`${_property},asc`);
        } else if (order === 'asc') {
            requestFirstPage(`${_property},desc`);
        } else if (order === 'desc') {
            requestFirstPage();
        } else {
            requestFirstPage(`${_property},asc`);
        }
    };

    const mapSortable = (_property: string): Sortable => {
        const _isLoading = isLoading;

        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: _isLoading,
            disabled: disableSortAndSelect,
            onSort: handleSort
        };
    };

    return (
        // provisional style
        <div className="container__invoices--header">
            <SortableColumn label={t('anticipation-invoices.invoices.invoice').toUpperCase()} sortable={mapSortable('nfNumber')} />
            <SortableColumn label={t('anticipation-invoices.invoices.serial').toUpperCase()} sortable={mapSortable('nfSerial')} />
            <SortableColumn
                label={t('anticipation-invoices.invoices.installmentNumber').toUpperCase()}
                sortable={mapSortable('installmentNumber')}
            />
            <SortableColumn label={t('anticipation-invoices.invoices.expireDate').toUpperCase()} sortable={mapSortable('expireDate')} />
            <SortableColumn label={t('anticipation-invoices.invoices.originalValue').toUpperCase()} sortable={mapSortable('value')} />
            <SortableColumn label={t('anticipation-invoices.invoices.discount').toUpperCase()} />
            <SortableColumn
                label={t('anticipation-invoices.invoices.anticipation').toUpperCase()}
                sortable={mapSortable('creditEstimate.total')}
            />
           
            <SelectableColumn
                label={t('anticipation-invoices.invoices.selectAll')}
                isSelected={isAllSelected}
                onSelect={handleSelectAll}
                isLoading={isLoading}
                disabled={disableSortAndSelect}
            />
        </div>
    );
};

export default InvoiceHeader;
