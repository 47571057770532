import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import BackButton from '../../../../../components/buttons/back-button/back-button';
import { useCompanyDefaultState } from '../../../../../reducer/hooks';
import FormatUtils from '../../../../../shared/util/format-utils';
import { AnticipationInvoicesLocation } from '../../anticipation-invoices';
import {
    INVOICE_SELECTION_HEIGHT,
    INVOICE_SELECTION_ITEM_HEIGHT,
    INVOICE_SELECTION_PAGE_SIZE,
    INVOICE_SELECTION_THRESHOLD,
    INVOICE_SELECTION_WIDTH
} from '../constants';
import { useInvoiceSelectionContext } from '../invoice-selection/invoice-selection-context';
import InvoiceListItem from './invoice-list-item';
import './invoice-list.scss';

export const InvoiceList = () => {
    const location = useLocation<AnticipationInvoicesLocation>();
    const defaultCompanyState = useCompanyDefaultState();
    const { t } = useTranslation();
    const {
        credits,
        creditCount,
        isLoading,
        isAllSelected,
        selected,
        hasError,
        requestFirstPage,
        requestNextPage,
        isCreditLoaded,
        handleSelect
    } = useInvoiceSelectionContext();

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const isSelected = (index: number): boolean =>
        isAllSelected || (!!(credits && credits[index]?.id) && !!selected.find(it => it === credits[index].id));

    const loadMoreItems = () => {
        if (!isLoading) {
            requestNextPage();
        }
        return null;
    };

    const handleTryAgain = () => {
        requestFirstPage();
    };

    if (hasError) {
        return (
            <div className="anticipation-invoice-selection__invoice-list--error">
                <div className="anticipation-invoice-selection__invoice-list__error-message">
                    {t('anticipation-invoices.invoices-error')}
                </div>
                <BackButton label={t('global.try-again').toUpperCase()} onClick={handleTryAgain} />
            </div>
        );
    }

    if (!isLoading && (!credits || credits.length === 0)) {
        const company = defaultCompanyState.company;
        return (
            <div className="anticipation-invoice-selection__invoice-list--empty">
                {t('anticipation-invoices.no-invoices', {
                    company_name: company?.name,
                    company_identification: FormatUtils.formatIdentification(company?.type, company?.identification),
                    program_name: location.state?.program.name
                })}
            </div>
        );
    }

    return (
        <InfiniteLoader
            isItemLoaded={isCreditLoaded}
            loadMoreItems={loadMoreItems}
            itemCount={creditCount}
            minimumBatchSize={INVOICE_SELECTION_PAGE_SIZE}
            threshold={INVOICE_SELECTION_THRESHOLD}
        >
            {({ onItemsRendered, ref }) => (
                <AutoSizer defaultHeight={INVOICE_SELECTION_HEIGHT} defaultWidth={INVOICE_SELECTION_WIDTH}>
                    {({ height, width }) => {
                        return (
                            <FixedSizeList
                                ref={ref}
                                onItemsRendered={onItemsRendered}
                                itemData={credits}
                                itemCount={creditCount}
                                itemKey={itemKey}
                                height={height}
                                width={width}
                                itemSize={INVOICE_SELECTION_ITEM_HEIGHT}
                            >
                                {p => <InvoiceListItem {...p} isSelected={() => isSelected(p.index)} onSelect={handleSelect} />}
                            </FixedSizeList>
                        );
                    }}
                </AutoSizer>
            )}
        </InfiniteLoader>
    );
};

export default InvoiceList;
