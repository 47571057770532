import { AxiosResponse } from 'axios';
import { CreditToList, CreditToListRequest } from '../../model/credit';
import { Page } from '../page';
import { api } from './api';

const BASE_URL = 'api/webapp/credits';

export const credits = () => {

    const listCredits = (request: CreditToListRequest): Promise<AxiosResponse<Page<CreditToList>>> => {
        return api.get<Page<CreditToList>>(`${BASE_URL}/programProvider/${request.programProviderId}`, { params: { ...request.pageable } });
    }

    return {
        listCredits
    };
};

export default credits();
