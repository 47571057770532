import Slide from '@material-ui/core/Slide';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../../../../components/buttons/action-button/action-button';
import { BatchToRequest } from '../../../../../model/batch';
import { CreditToRequest } from '../../../../../model/credit';
import { HttpRequestStatus } from '../../../../../model/enums/httpRequestStatus';
import { requestBatchRequest } from '../../../../../reducer/batch/request/actions';
import { useBatchRequestState, useEstimateRequestState, useRootDispatch } from '../../../../../reducer/hooks';
import AnticipationTermSession from '../anticipation-term-session/anticipation-term-session';
import './anticipation-request-confirmation.scss';

export interface AnticipationRequestConfirmationProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

const useBatchRequestStateAwareness = () => {
    const state = useBatchRequestState();

    const [isLoading, setLoading] = React.useState<boolean>(false);
    const hasError = state.status === HttpRequestStatus.ERROR;

    React.useEffect(() => {
        if (state.status === HttpRequestStatus.ON_GOING) {
            setLoading(true);
        } else if (state.status === HttpRequestStatus.SUCCESS || state.status === HttpRequestStatus.ERROR) {
            setLoading(false);
        }
    }, [state.status]);

    return { isLoading, hasError };
};

export const AnticipationRequestConfirmation = (props: AnticipationRequestConfirmationProps) => {
    const { open, onCancel, onConfirm } = props;
    const { t } = useTranslation();
    const estimateState = useEstimateRequestState();
    const batchState = useBatchRequestState();

    const dispatch = useRootDispatch();
    const { isLoading } = useBatchRequestStateAwareness();

    const handleConfirmation = () => {
        const _credits: CreditToRequest[] = estimateState?.estimate?.creditEstimates.map(it => ({ id: it.id })) ?? [];
        const request: BatchToRequest = { credits: _credits };
        dispatch(requestBatchRequest(request));
    };

    React.useEffect(() => {
        if (batchState.status === HttpRequestStatus.SUCCESS) {
            onConfirm();
        }
    }, [batchState.status, onConfirm]);

    return (
        <Slide in={open} direction="up" timeout={500} unmountOnExit>
            <div className="anticipation-request-confimation">
                <div className="body__confirmation--itens">
                    <div className="body__confirmation--content">
                        <div className="body__confirmation--image" />
                    </div>
                    <div className="body__confirmation--title-start">
                        <span className="body__confirmation--title-start-one">
                            {t('anticipation-simulation.anticipation-request-confirmation.start')}
                        </span>
                        <br />
                        <span className="body__confirmation--title-start-two">
                            {t('anticipation-simulation.anticipation-request-confirmation.days-to-pay', {
                                count: estimateState.estimate?.daysToPay ?? 0
                            })}
                        </span>
                        {t('anticipation-simulation.anticipation-request-confirmation.end')}
                    </div>

                    <div className="body__confirmation--terms">
                        <AnticipationTermSession />
                    </div>
                    <div className="body__confirmation--content">
                        <div className="body__confirmation--button">
                            <button className="body__confirmation--button-cancel" onClick={onCancel}>
                                {t('anticipation-simulation.anticipation-request-confirmation.button.cancel').toUpperCase()}
                            </button>
                            <ActionButton
                                label={t(
                                    'anticipation-simulation.anticipation-request-confirmation.button.confirm'
                                ).toUpperCase()}
                                onClick={handleConfirmation}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    );
};

export default AnticipationRequestConfirmation;
